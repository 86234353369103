import { combineReducers } from "redux";
import settings from "./settings";
import auth from "./auth";
import alert from './alert';
import humanin from './humaninterfaceReducers';
import mode from './mode';
const Reducers = combineReducers({
  settings,
  auth,
  alert,
  humanin,
  mode
 
});

export default Reducers;
