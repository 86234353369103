import React from "react";
import Routes from "./routes/Routes";
import './App.css';
if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
}
const App = () => {
  return (
    <div>
      <Routes/>
    </div>
  );
};

export default App;
