import {
    AUTH_MODE
  } from '../actions/types';
  
  const initialState = {
    authMode: localStorage.getItem('authMode') ? JSON.parse(localStorage.getItem('authMode')) : 1,
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case AUTH_MODE:
        localStorage.setItem('authMode',payload)
        return {
          ...state,
          ...payload,
          authMode: payload
        }
      default:
        return state;
    }
  
  }
  