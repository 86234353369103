import axios from "axios";
import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	GET_COUNTRYS,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	CLEAR_PROFILE,
	FORGOT_PASSWORD,
	LOGOUT,
	// UPDATE_ORG,
	// ORG_ERROR,
	// CALLBACK_ERROR,
	// UPDATE_CALLBACK,
	START_REG_LOADER,
	// START_ORG_LOADER,
	AUTH_MODE,
	LOGIN_SANDBOX,
	DOWNLOAD_PRIVACY,
	LOGIN_AUTHENITICATED
} from "./types";
import setAuthToken from "../utils/tools";
import { sendLogs } from "./humanInterfaceActions";
import ls from "localstorage-slim";
import { env as environment } from "./environment";

// Get post
export const getCountries = () => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const res = await axios.get(environment.verifiedcountrylist, config);

		dispatch({
			type: GET_COUNTRYS,
			payload: res.data,
		});
	} catch (err) {
		console.log("in getcountries");
		dispatch({
			type: AUTH_ERROR,
			// payload: { msg: err.response.statusText, status: err.response.status }
		});
	}
};

//update org
// export const updateOrg = (formData) => async (dispatch) => {
// 	dispatch({ type: START_ORG_LOADER });
// 	// var json = { "apikey": apikey, formData}
// 	try {
// 		const res = await axios.post(environment.updateorganization, formData);

// 		//console.log("res update1 " + JSON.stringify(res));
// 		//console.log("res update data " + JSON.stringify(res.data));
// 		//console.log("res update data " + JSON.stringify(res.data.error));
// 		if (res.data.error === true) {
// 			dispatch({
// 				type: ORG_ERROR,
// 				payload: res.data,
// 			});
// 			sendLogs("Org Update failed", res.data, "actions/auth.js");
// 		} else {
// 			dispatch({
// 				type: UPDATE_ORG,
// 				payload: res.data,
// 			});
// 			sendLogs("Org Update", "Org updated successfully", "actions/auth.js");
// 		}
// 	} catch (err) {
// 		console.log("in updateorg");
// 		dispatch({
// 			type: AUTH_ERROR,
// 			// payload: err.response
// 		});
// 		sendLogs("Org Update failed", err, "actions/auth.js");
// 	}
// };

//update org
// export const updateCallback = (formData) => async (dispatch) => {
// 	// var json = { "apikey": apikey, formData}
// 	//console.log("hello");
// 	try {
// 		const res = await axios.post(environment.updateorganization, formData);
// 		//console.log("res update1 " + JSON.stringify(res));
// 		//console.log("res update data " + JSON.stringify(res.data));
// 		//console.log("res update data " + JSON.stringify(res.data.error));
// 		if (res.data.error === true) {
// 			dispatch({
// 				type: CALLBACK_ERROR,
// 				payload: res.data,
// 			});
// 		} else {
// 			dispatch({
// 				type: UPDATE_CALLBACK,
// 				payload: res.data,
// 			});
// 		}
// 	} catch (err) {
// 		console.log("in update callback");
// 		dispatch({
// 			type: AUTH_ERROR,
// 			// payload: err.response
// 		});
// 	}
// };

// Register User
export const register =
	({ email, country, password, firstname, lastname, companyname, building, roleincompany }) =>
	async (dispatch) => {
		dispatch({ type: START_REG_LOADER });
		const config = {
			headers: {
				"Content-Type": "application/json",
			},
		};
		const signupdata = {};
		if (email) signupdata.email = email;
		if (country) signupdata.country = country;
		if (password) signupdata.password = password;
		if (firstname) signupdata.firstname = firstname;
		if (lastname) signupdata.lastname = lastname;
		if (companyname) signupdata.companyname = companyname;
		if (building) signupdata.building = building;
		if (roleincompany) signupdata.roleincompany = roleincompany;

		try {
			const res = await axios.post(environment.signup, signupdata, config);
			console.log("res signup " + JSON.stringify(res));
			if (res.data.message === "plz check you email") {
				dispatch({
					type: REGISTER_SUCCESS,
					payload: { payload: res.data },
				});
				// sendLogs("Org Register", "Org registered successfully", "actions/auth.js");
			} else {
				dispatch({
					type: REGISTER_FAIL,
					payload: { payload: res.data },
				});
				// sendLogs("Org Registration failed", res.data, "actions/auth.js");
			}
			// if (res.headers.authorization) {
			//   setAuthToken(res.headers.authorization);
			// }
		} catch (err) {
			
			console.log("catch===>", err.response);
			dispatch({
				type: REGISTER_FAIL,
				payload: err?.response?.data,
			});
			// sendLogs("Org Registration failed", err, "actions/auth.js");
		}
	};

	export const twoFactorLogin = (email, otp,twoFactorId, authMode) => async (dispatch) => {
		//dispatch({ type: START_LOGIN_LOADER });
		console.log("authMode " + authMode);
		ls.remove("token");
		ls.remove("alldata");
		ls.remove("apikey");
		ls.remove("email");
		ls.remove("orgid");
		ls.remove("roles");
		ls.set("roles", "null");
		ls.remove("stripeid");
		ls.remove("planid");
		ls.clear();
		console.log('inside two')
		
		const config = {
		  headers: {
			"Content-Type": "application/json",
			
		  },
		};
		// const body = JSON.stringify({ email, password });
		const body = { email,fusionotp:otp,twoFactorId };
		try {
		  // const res = await axios.post(environment.login, body, config);
	
		  //for test above is main
		  console.log("calling an api")
		  const res= await axios.post(environment.twoFactorLogin,body,config);
		  // ls.set('Authorization', setAuthToken);
		  // ls.get('Authorization');
		  // console.log("res  " + JSON.stringify(res));
		  console.log("res **** " + JSON.stringify(res));
		  console.log("res login data " + res.data);
		  console.log("res login " + res.data.error);
		  console.log("authmode::",authMode)
		  console.log("authMode type:", typeof authMode);
		  console.log("res header " + JSON.stringify(res.headers));
		  // if(res.data.error === "email is invalid" || res.data.message === "password is invalid" || res.data.message === "Please provide correct password!" || res.data.message != "undefined" || res.data.message != undefined)
		  if (res.data.error === true) {
			//console.log("hii");
			dispatch({
			  type: LOGIN_FAIL,
			  payload: { payload: res.data },
			});
			sendLogs("Org Login failed", res.data, "actions/auth.js");
		  } else if (res.data.statusCode === 200 ) {
			
			// if (res.headers.authorization) {
			//   console.log("localhost auth",res.headers.authorization)
			//   setAuthToken(res.headers.authorization);
			// }
			//console.log(authMode);
			if (authMode === 1 || authMode === "1") {
			  dispatch({
				type: LOGIN_SUCCESS,
				payload: { headers: res.headers, payload: res.data },
			  });
			  console.log('inside if auth')
			  console.log("login success!!!")
			  console.log("check1234",ls.get("token"))
	
			  sendLogs(
				"Org Login success",
				"Org logged in successfully",
				"actions/auth.js"
			  );
			} else {
			  console.log("sandbox");
			  dispatch({
				type: LOGIN_SANDBOX,
				payload: { headers: res.headers, payload: res.data },
			  });
			  sendLogs(
				"Sandbox Login",
				"Sandbox logged in successfully",
				"actions/auth.js"
			  );
			}
		  } else {
			// //console.log("res " + JSON.stringify(res.headers));
			//console.log("res " + res);
			console.log("login fail")
			dispatch({
			  type: LOGIN_FAIL,
			  payload: { payload: res.data },
			});
		  }
		} catch (error) {
		  console.log("error block",error.response?.data);
		  dispatch({
			type: LOGIN_FAIL,
			payload: { payload: error.response?.data },
		  });
		}
		// }
	  };
	

// Login User
export const login = (email, password, authMode) => async (dispatch) => {
    //dispatch({ type: START_LOGIN_LOADER });
    console.log("authMode " + authMode);
    ls.remove("token");
    ls.remove("alldata");
    ls.remove("apikey");
    ls.remove("email");
    ls.remove("orgid");
    ls.remove("roles");
    ls.set("roles", "null");
    ls.remove("stripeid");
    ls.remove("planid");
    ls.clear();
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        
      },
    };
    // const body = JSON.stringify({ email, password });
    const body = { email, password };
    try {
      // const res = await axios.post(environment.login, body, config);

      //for test above is main
      const res= await axios.post(environment.login,body,config);
      // ls.set('Authorization', setAuthToken);
      // ls.get('Authorization');
      // console.log("res  " + JSON.stringify(res));
      console.log("res **** " + JSON.stringify(res));
      console.log("res login data " + res.data);
      console.log("res login " + res.data.error);
      console.log("authmode::",authMode)
      console.log("authMode type:", typeof authMode);
      console.log("res header " + JSON.stringify(res.headers));
      // if(res.data.error === "email is invalid" || res.data.message === "password is invalid" || res.data.message === "Please provide correct password!" || res.data.message != "undefined" || res.data.message != undefined)
      if (res.data.error === true) {
        // //console.log("hii");
        dispatch({
          type: LOGIN_FAIL,
          payload: { payload: res.data },
        });
        sendLogs("Org Login failed", res.data, "actions/auth.js");
      } else if (res.data.statusCode === 242 ) {
        
        if (authMode === 1 || authMode === "1") {
          
          console.log('inside if auth')
          console.log("login success!!!")
          dispatch({
            type: LOGIN_AUTHENITICATED,
            payload: { headers: res.headers, payload: res.data,email },
          })
          sendLogs(
            "Org Login success",
            "Org logged in successfully",
            "actions/auth.js"
          );
        } else {
          console.log("sandbox");
          dispatch({
            type: LOGIN_SANDBOX,
            payload: { headers: res.headers, payload: res.data },
          });
          sendLogs(
            "Sandbox Login",
            "Sandbox logged in successfully",
            "actions/auth.js"
          );
        }
      } else {
        // //console.log("res " + JSON.stringify(res.headers));
        //console.log("res " + res);
        console.log("login fail")
        dispatch({
          type: LOGIN_FAIL,
          payload: { payload: res.data },
        });
      }
    } catch (error) {
      console.log("error block",error.response?.data);
      dispatch({
        type: LOGIN_FAIL,
        payload: { payload: error.response?.data },
      });
    }
    // }
  };

  //login otp
export const sendLoginOtp = (twoFactorId,methodId) => async (dispatch) => {
	try{
	  const body = {
		twoFactorId:twoFactorId,
		methodId:methodId
	  }
  
	  const res = await axios.post(environment.sendLoginOtp,body)
	  console.log("otp sent!!!")
  
	}catch(error){
	  console.log(error);
	}
  }
// Login User
export const forgoruser = (forgotData, history) => async (dispatch) => {
	dispatch({ type: START_REG_LOADER });
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const res = await axios.post(environment.forgot, forgotData, config);
		// localStorage.setItem('Authorization', setAuthToken);
		// localStorage.getItem('Authorization');
		dispatch({
			type: FORGOT_PASSWORD,
			payload: { payload: res.data },
		});
	} catch (err) {
		console.log("in auth action forgoruser");
		dispatch({
			type: AUTH_ERROR,
		});
	}
};

// Login User
export const resetPassword = (forgotData, history) => async (dispatch) => {
	dispatch({ type: START_REG_LOADER });
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const res = await axios.post(environment.resetpassword, forgotData, config);
		// localStorage.setItem('Authorization', setAuthToken);
		// localStorage.getItem('Authorization');
		//console.log(res.data.message);
		if (res.data.message === "success") {
			history.push("/authentication/login");
		}
	} catch (err) {
		console.log("in auth action resetpassword");
		dispatch({
			type: AUTH_ERROR,
		});
	}
};

// Login User
export const updatePassword = (forgotData, history) => async (dispatch) => {
	dispatch({ type: START_REG_LOADER });
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};

	try {
		console.log("in tr");
		const res = await axios.post(environment.updatepassword, forgotData, config);
		// localStorage.setItem('Authorization', setAuthToken);
		// localStorage.getItem('Authorization');
		console.log(res.data.message);

		// if(res.data.message === 'success'){
		//   console.log("in if")
		//   history.push('/authentication/login');
		//   }
	} catch (err) {
		console.log("in updatepassword");
		dispatch({
			type: AUTH_ERROR,
		});
	}
};

export const changeMode = (data) => (dispatch) => {
	dispatch({
		type: AUTH_MODE,
		payload: data,
	});
	//sendLogs("changeMode", "Sandbox mode switched", "actions/auth.js");
};

export const changeModeLogout = (data) => (dispatch) => {
	dispatch({
		type: AUTH_MODE,
		payload: data,
	});
	// sendLogs("Org Logout", "Logged out successfully and profile cleared", "actions/auth.js");
	localStorage.removeItem("email");
	dispatch({ type: CLEAR_PROFILE });
	dispatch({ type: LOGOUT });
};

export const logout = () => (dispatch) => {
	// sendLogs("Org Logout", "Logged out successfully and profile cleared", "actions/auth.js");
	localStorage.removeItem("email");
	localStorage.removeItem("liveapi");
	localStorage.removeItem("sandboxapi");
	localStorage.removeItem("alldataa");
	localStorage.removeItem("authMode");
	localStorage.removeItem("tokenTest");
	localStorage.removeItem("iso_code");
	localStorage.removeItem("country");
	localStorage.removeItem("logoutTimer");
	dispatch({ type: CLEAR_PROFILE });
	dispatch({ type: LOGOUT });
	dispatch({ type: DOWNLOAD_PRIVACY,payload:false });
};
export const changeDownloadPrivacy = (data) => (dispatch) => {
	// sendLogs("Org Logout", "Logged out successfully and profile cleared", "actions/auth.js");
	dispatch({ type: DOWNLOAD_PRIVACY,payload:data });
};
