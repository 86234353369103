// import axios from "axios";
//import { setAlert } from './alert';
//import {  useHistory } from "react-router-dom";
import {
	// GET_BUTTON,
	// GET_BUTTONS,
	// ADD_BUTTON,
	// CLEAR_BUTTON,
	// UPDATE_BUTTON,
	// AUTH_ERROR,
	// AUTH_ERRORS,
	// GET_VALIDATION_HEADER,
	// GET_INTEGRATION_HEADER,
	// GET_BUTTON_SETTING_HEADER,
	// GET_VIEW_DOC_HEADER,
	//DELETE_BUTTON,
	// GET_REFERENCE_HEADER,
	// REMOVE_BUTTON_ALERT,
	// GET_API_BUTTON,
	// LOAD_BUTTON,
	// EMPTY_BLANK_URL,
	REMOVE_AUTH_ERROR,
	// GET_MASTERFIELDDATA,
} from "./types";
//import { sendLogs } from "../actions/logs";
// import { env as environment } from "./environment";
//import setAuthToken from '../utils/tools';
//import refreshAuth from "./refreshAuth";
//var apikey = localStorage.getItem('apikey');
// console.log("In button above axios authorization");
// axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
// Get buttons
// export const getbuttons = () => async (dispatch) => {
// 	dispatch({ type: CLEAR_BUTTON });
// 	var json = { apikey: localStorage.getItem("apikey") };
// 	try {
// 		const res = await axios.post(environment.invite, json);
// 		//sendLogs("getbuttons", "getbuttons success", "actions/button.js");
// 		//console.log("getbuttons " + res.data + res.data.error);
// 		dispatch({
// 			type: GET_BUTTONS,
// 			payload: res.data,
// 		});
// 	} catch (err) {
// 		console.log("auth err 1: " + err);
// 		//sendLogs("getbuttons Failed", err.message, "actions/button.js");
// 		dispatch({
// 			type: AUTH_ERROR,
// 			payload: err.message,
// 		});
// 	}
// };
// export const getbuttonslist = () => async (dispatch) => {
// 	dispatch({ type: CLEAR_BUTTON });
// 	var json = { apikey: localStorage.getItem("apikey") };
// 	try {
// 		const res = await axios.post(environment.btn_list, json);
// 		// console.log("btn_list==>" + res.data);
// 		dispatch({
// 			type: GET_BUTTONS,
// 			payload: res.data,
// 		});
// 	} catch (err) {
// 		console.log("auth err 1: " + err);
// 		dispatch({
// 			type: AUTH_ERROR,
// 			payload: err.message,
// 		});
// 	}
// };
// export const originalgetbuttons = () => async (dispatch) => {
// 	console.log("hii12");
// 	dispatch({ type: GET_VALIDATION_HEADER });
// 	dispatch({ type: CLEAR_BUTTON });
// 	dispatch({ type: EMPTY_BLANK_URL });
// 	var json = { apikey: localStorage.getItem("apikey") };
// 	try {
// 		const res = await axios.post(environment.btnstatus, json);
// 		console.log("originalgetbuttons " + res.data + res.data.error);
// 		//sendLogs("originalgetbuttons", "originalgetbuttons success", "actions/button.js");
// 		dispatch({
// 			type: GET_BUTTONS,
// 			payload: res.data,
// 		});
// 	} catch (err) {
// 		//console.log(localStorage.getItem('refreshToken'));
// 		console.log("In catch " + err);
// 		if (localStorage.getItem("refreshToken") && err.message === "Network Error") {
// 			dispatch(refreshAuth("originalgetbuttons"));
// 		} else if (localStorage.getItem("refreshToken") === null && err.message === "Network Error") {
// 			//sendLogs("originalgetbuttons Failed", err.message, "actions/button.js");
// 			console.log("hii");
// 			dispatch({
// 				type: AUTH_ERRORS,
// 				payload: err.message,
// 			});
// 		} else {
// 			//sendLogs("originalgetbuttons Failed", err.message, "actions/button.js");
// 			console.log("auth error 2 " + JSON.stringify(err));
// 			dispatch({
// 				type: AUTH_ERROR,
// 				payload: err.message,
// 			});
// 		}
// 	}
// };

// export const apiref = () => async (dispatch) => {
// 	dispatch({ type: GET_REFERENCE_HEADER });
// 	dispatch({ type: CLEAR_BUTTON });
// 	var json = { apikey: localStorage.getItem("apikey") };
// 	try {
// 		const res = await axios.post(environment.btn_list, json);
// 		// console.log("apiref " +  JSON.stringify(res));
// 		if (res.data.error === true) {
// 			//sendLogs("apiref Failed", res.data, "actions/button.js");
// 			if (res.data.status === 401) {
// 				if (localStorage.getItem("refreshToken")) {
// 					dispatch(refreshAuth("apiref"));
// 				} else {
// 					//console.log("auth err 7 : " + err);
// 					dispatch({
// 						type: AUTH_ERROR,
// 						// payload: err.response
// 					});
// 				}
// 			} else {
// 				//sendLogs("apiref Failed", res.message, "actions/button.js");
// 				console.log("auth error 8 " + res.data.error);
// 				dispatch({
// 					type: AUTH_ERROR,
// 					payload: res.message,
// 				});
// 			}
// 		} else {
// 			//sendLogs("apiref", "apiref success", "actions/button.js");
// 			dispatch({
// 				type: GET_BUTTONS,
// 				payload: res.data,
// 			});
// 		}
// 	} catch (err) {
// 		// console.log("err " + JSON.stringify(err )  + " " + err.message);
// 		if (localStorage.getItem("refreshToken") && err.message === "Network Error") {
// 			dispatch(refreshAuth("apiref"));
// 		} else if (localStorage.getItem("refreshToken") === null && err.message === "Network Error") {
// 			console.log("hii");
// 			dispatch({
// 				type: AUTH_ERRORS,
// 				payload: err.message,
// 			});
// 		} else {
// 			console.log("auth err 9 : " + err);
// 			dispatch({
// 				type: AUTH_ERROR,
// 				payload: err.message,
// 			});
// 		}
// 	}
// };

// export const getapibutton = (buttonid) => async (dispatch) => {
// 	// dispatch({ type: GET_REFERENCE_HEADER });
// 	// dispatch({ type: CLEAR_BUTTON });
// 	////console.log(buttonid + "buttonid")
// 	let json;
// 	if (buttonid === "" || buttonid === null || buttonid === "null" || buttonid === undefined) {
// 		////console.log("hii");
// 		json = { buttonid: localStorage.getItem("firstbtnid"), usertoken: guid() };
// 	} else {
// 		console.log("getapibutton");
// 		json = { buttonid: buttonid, usertoken: guid() };
// 	}
// 	try {
// 		const res = await axios.post(environment.getbtndata, json);
// 		//sendLogs("getapibutton", "getapibutton success", "actions/button.js");
// 		//console.log("getapibutton " + res.data + res.data.error);
// 		dispatch({
// 			type: GET_API_BUTTON,
// 			payload: { buttonid: json.buttonid, payload: res.data },
// 		});
// 	} catch (err) {
// 		console.log("auth err : " + err);
// 		//sendLogs("getapibutton Failed", err.message, "actions/button.js");
// 		dispatch({
// 			type: AUTH_ERROR,
// 			payload: err.message,
// 		});
// 	}
// };

// export const apiIntegration = () => async (dispatch) => {
// 	dispatch({ type: GET_INTEGRATION_HEADER });
// };

// function guid() {
// 	function s4() {
// 		return Math.floor((1 + Math.random()) * 0x10000)
// 			.toString(16)
// 			.substring(1);
// 	}
// 	return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
// }

// export const buttonAdd = (data) => async (dispatch) => {
// 	const json1 = { apikey: localStorage.getItem("apikey"), buttonid: guid(), data: data };

// 	//console.log(json1);
// 	try {
// 		const res = await axios.post(environment.btn_data, json1);
// 		//sendLogs("buttonAdd", "buttonAdd success", "actions/button.js");
// 		console.log("Add button " + JSON.stringify(res));
// 		dispatch({
// 			type: ADD_BUTTON,
// 			//  payload: res.data
// 		});
// 		dispatch(originalgetbuttons());
// 	} catch (err) {
// 		//console.log("err " + err + localStorage.getItem('refreshToken'));
// 		if (localStorage.getItem("refreshToken") && err.message === "Network Error") {
// 			dispatch(refreshAuth("buttonAdd", data));
// 		} else {
// 			//sendLogs("buttonAdd Failed", err.message, "actions/button.js");
// 			console.log("auth err 4: " + err);
// 			dispatch({
// 				type: AUTH_ERROR,
// 				payload: err.message,
// 			});
// 		}
// 	}
// };

// export const getButtonSettingHeader = (button_id) => async (dispatch) => {
// 	//console.log(button_id);
// 	dispatch({
// 		type: GET_BUTTON_SETTING_HEADER,
// 		payload: button_id,
// 	});
// 	//getapibutton(button_id);
// };

// export const getButtonData = (button_id) => async (dispatch) => {
// 	dispatch({ type: EMPTY_BLANK_URL });
// 	dispatch({ type: LOAD_BUTTON });
// 	const json = { buttonid: button_id, usertoken: guid() };
// 	console.log("getButtonData");
// 	try {
// 		const res = await axios.post(environment.getbtndata, json);
// 		console.log("button " + JSON.stringify(res.data));
// 		//sendLogs("getButtonData", "getButtonData success", "actions/button.js");
// 		dispatch({
// 			type: GET_BUTTON,
// 			payload: res.data,
// 		});
// 	} catch (err) {
// 		console.log("auth err 5 : " + err);
// 		//sendLogs("getButtonData Failed", err.message, "actions/button.js");
// 		dispatch({
// 			type: AUTH_ERROR,
// 			payload: err.message,
// 		});
// 	}
// };

// export const getmasterfieldsdata = () => async (dispatch) => {
// 	try {
// 		const res = await axios.get(environment.getmasterfields);
// 		console.log("getmasterfieldsdata" + JSON.stringify(res));
// 		//sendLogs("getmasterfieldsdata", "getmasterfieldsdata success", "actions/button.js");
// 		dispatch({
// 			type: GET_MASTERFIELDDATA,
// 			payload: res.data,
// 		});
// 	} catch (err) {
// 		console.log("auth err 6 : " + err);
// 		//sendLogs("getmasterfieldsdata Failed", err.message, "actions/button.js");
// 		dispatch({
// 			type: AUTH_ERROR,
// 			payload: err.message,
// 		});
// 	}
// };

// export const getViewDoc = (button_id) => async (dispatch) => {
// 	dispatch({
// 		type: GET_VIEW_DOC_HEADER,
// 		payload: button_id,
// 	});
// 	//getapibutton(button_id);
// };

// export const updateButton = (data) => async (dispatch) => {
// 	//dispatch({type:ADD_BUTTON});
// 	try {
// 		const res = await axios.post(environment.update_btn, data);
// 		//sendLogs("updateButton", "updateButton success", "actions/button.js");
// 		//console.log("button " + JSON.stringify(res));
// 		dispatch({
// 			type: UPDATE_BUTTON,
// 			payload: res.data,
// 		});
// 	} catch (err) {
// 		if (localStorage.getItem("refreshToken") && err.message === "Network Error") {
// 			dispatch(refreshAuth("updateButton", data));
// 		} else {
// 			//sendLogs("updateButton Failed", err.message, "actions/button.js");
// 			console.log("auth err 3 : " + err);
// 			dispatch({
// 				type: AUTH_ERROR,
// 				payload: err.message,
// 			});
// 		}
// 	}
// };

// export const deleteButton = (data, history) => (dispatch) => {
// 	axios
// 		.post(environment.deletebutton, data)
// 		.then((res) => history.push("/client/validation-buttons"))
// 		.catch((err) => {
// 			if (localStorage.getItem("refreshToken") && err.message === "Network Error") {
// 				dispatch(refreshAuth("deleteButton", data));
// 			} else {
// 				console.log("auth err 4 : " + err);
// 				//sendLogs("deleteButton Failed", err.message, "actions/button.js");
// 				dispatch({
// 					type: AUTH_ERROR,
// 					payload: err.message,
// 				});
// 			}
// 		});
// };

// export const deleteButton = (data,history) =>  dispatch => {

//     axios
//     .post(environment.deletebutton,data);
//     //console.log("Add button " + JSON.stringify(res));
//     // dispatch({
//     //   type: DELETE_BUTTON,
//     //   payload: res.data
//     // });
//     .then(res => history.push('/client/admin-dash'));
//     //dispatch(originalgetbuttons());
//   // }
//   .catch (err => {
//     if(localStorage.getItem('refreshToken'))
//     {
//       dispatch(refreshAuth('deleteButton',data));
//     }
//     else
//     {
//     dispatch({
//       type: AUTH_ERROR,
//       payload: err.response
//       });
//     }
//   });
// };

// export const removeAlert = () => async (dispatch) => {
// 	dispatch({
// 		type: REMOVE_BUTTON_ALERT,
// 	});
// 	dispatch(originalgetbuttons());
// };

// export const changeUrltiApiIntegration = (btn_id, history) => async (dispatch) => {
// 	localStorage.setItem("firstbtnid", btn_id);
// 	// <Redirect to={'/client/developers/api-refrence'}/>;
// 	// const history = useHistory();
// 	// history.push('/client/developers/api-refrence');
// };

export const removeSweatAlert = (history) => (dispatch) => {
	dispatch({ type: REMOVE_AUTH_ERROR });
	// dispatch({ type: AUTH_ERRORS });
	//history.push('/client/validation-buttons')
};
