import axios from 'axios';

const setAuthToken = token => {
  if (token) {
  //  alert("token " + token);
  console.log("In setAuthToken 1");
    axios.defaults.headers.common['Authorization'] = token;
  } else if(localStorage.getItem('token')) {
  //  alert("hii")
  console.log("In setAuthToken 2");
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
  }  else {
    console.log("In setAuthToken 3");
    alert("token " + token + localStorage.getItem('token'));
    delete axios.defaults.headers.common['Authorization'];
  }
};

export default setAuthToken;
