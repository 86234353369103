/* eslint-disable */
import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import {
	Nav,
	Navbar,
	NavbarBrand,
	Collapse,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Form,
	Dropdown,
	InputGroup,
	InputGroupAddon,
	Button,
	Input,
} from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import { sendLogs } from "../../../actions/logs";
import PropTypes from "prop-types";
import { logout } from "../../../actions/auth";
import {getAutodata,setSearchData,setAutoTabPaginationPageNo,setFormData,getPendingdata, getBlockedSessions} from "../../../actions/humanInterfaceActions";
import { removeSweatAlert } from "../../../actions/button";
import SearchBar from "material-ui-search-bar";
//import { getbillingOrg } from "../../../actions/org";
//import { getlogform } from "../../../actions/logs";
import { env as environment } from "../../../actions/environment";
//import AddUserModal from "./AddUserModal.js";
//import AddButton from "./AddButton.js";
//import ModeTonggle from "../sidebar/ModeTonggle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { useIdleTimer } from "react-idle-timer";
/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from "../../../assets/images/logo_dirosvg.png";
//import bell from "../../../assets/images/bell-icon.png"

const Header = ({
	viewdoc_msg,
	//getlogform,
	removeSweatAlert,
	roles,
	logTab,
	isAuthenticated,
	 getAutodata,
	 getPendingdata,
	 setSearchData,
	 setFormData,
	 setAutoTabPaginationPageNo,
	 formData,
	 getBlockedSessions,
	//getrequested,
	//changeUrltiApiIntegration,
	//getReviewPending,
	//getApproved,
	//getRejected,
	//searchTable,
	//getbillingOrg,
	auth: { loading, user, login_user },
	// header: { subheading, search, adduser, buttonSettingbut, reqdocbut, button_id },
	//errors: { errorMsg, error_modal },
	//table: { limit, limitapproves, limitrejets, limitrequest },
	humanin:{subheading,searchdata},
	logout,
	url,
}) => {
	//const [buttonId, setButtonid] = useState();
	if (!isAuthenticated) {
		return <Redirect to="/login" />;
		// location.reload();
	}

	//--- Auto Logout when the user is idle ---
	//--- Timer starts when this component loads after login ---
	const timeout = 10 * 3600000; // 10 hours
	//--- react-idle-time setup ---\\
	const { reset, getRemainingTime } = useIdleTimer({
		timeout,
	});

	useEffect(() => {
		// //---- Takes to login page on back button click if already logged out  ---
		// window.addEventListener("popstate", (event) => {
		// 	if (localStorage.getItem("isLoggedOut") === "true") logout();
		// });
		// //---- Show logged out popup on refresh button click ----
		// if (localStorage.getItem("isLoggedOut") === "true") {
		// 	setLoggedOut(true);
		// 	sendLogs("Auto-logout popup", "setLoggedout set to true in localstorage", "actions/auth.js");
		// }
		const timer = setInterval(() => {
			localStorage.setItem("logoutTimer", getRemainingTime());
			// ----- logout after 12 hrs (43200000)-----
			if (localStorage.getItem("logoutTimer") === 0 || new Date() - new Date(localStorage.getItem("loggedInAt")) >= 43200000) {
				localStorage.setItem("isLoggedOut", "true");
				logout();
			}
		}, 1000);
		return () => {
			clearInterval(timer);
		};
	}, []);

	//const onApiIntegration = () => {
	//changeUrltiApiIntegration(button_id);
	// console.log("hii 1");
	//     // const history = useHistory();
	//     let navigate = useNavigate();
	//     navigate('/client/developers/api-refrence', { replace: true });
	//    // return <Redirect to='/client/developers/api-refrence'/>;
	//};

	// useEffect(() => {
	// 	// apiref();
	// 	getbillingOrg();
	// }, [getbillingOrg]);

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState('orgName');
	const [finalData , setFinalData] =useState({enteredString:"",selectedOption:"orgName"}) 
	const [autoString,setAutoString] =useState ('')

	const { enteredString } = formData || {};

	const onChange = (e) => {
		setSearchData(e);
		setFinalData({ ...finalData, enteredString: e });
		setAutoString(e);
		// searchTable(enteredString , subheading);

	};
	console.log("selected option and string",selectedOption,autoString,subheading);
	const onSubmit = async (e) => {
		e.preventDefault();
		setAutoTabPaginationPageNo(0);
		getAutodata(0,autoString);
		
	};
	
	const onSubmitRefresh = async (e) => {
		e.preventDefault();
		console.log("header refresh check", subheading)
		if(subheading=="auto"){
			setAutoTabPaginationPageNo(0);
			getAutodata(0,"");
		}

		if(subheading === "pending" ){
			console.log("header refresh check if", subheading)
			getPendingdata()
		}

		if(subheading === "blocked" ){
			console.log("header refresh check if", subheading)
			getBlockedSessions({limit: 0, search:"" })
		}
		
		setSearchData("");
		setFinalData({ ...finalData, enteredString: "",selectedOption: selectedOption });
		setFormData({ enteredString: "",selectedOption: "" });
		
		// if (subheading === "New Request") {
		// 	console.log("innnn" + limitrequest / 20);

		// 	getrequested(limitrequest / 20);
		// } else if (subheading === "Review pending") {
		// 	console.log("in subheading" + limit);
		// 	getReviewPending(limit);
		// } else if (subheading === "Approved") {
		// 	console.log("in approve" + limitapproves);
		// 	getApproved(limitapproves);
		// } else if (subheading === "Rejected") {
		// 	getRejected(limitrejets);
		// }
		// searchTable(enteredString, subheading);
	};

	// const subheadingdata = (
	// 	<div
	// 		style={{ color: "#212529", fontWeight: 400, letterSpacing: "2px", fontSize: 17, display: "flex", marginTop: "3px" }}
	// 		className="font-weight-medium rounded-top py-3 px-4"
	// 	>
	// 		{/* <i className="mdi mdi-equal mr-2"> </i> */}
	// 		{subheading}
	// 	</div>
	// );	
	const authLinks = (
		<DropdownItem onClick={logout} href="#!">
			<i className="fa fa-power-off mr-1 ml-1" /> Logout
		</DropdownItem>
	);

	const guestLinks = (
		<DropdownItem href="/authentication/login">
			<i className="fa fa-power-off mr-1 ml-1" /> Login
		</DropdownItem>
	);
	const [isOpen, setIsOpen] = useState(false);
	const settings = useSelector((state) => state.settings);
	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const showMobilemenu = () => {
		document.getElementById("main-wrapper").classList.toggle("show-sidebar");
	};

	// const sidebarHandler = () => {
	// 	let element = document.getElementById("main-wrapper");
	// 	switch (settings.activeSidebarType) {
	// 		case "full":
	// 		case "iconbar":
	// 			element.classList.toggle("mini-sidebar");
	// 			if (element.classList.contains("mini-sidebar")) {
	// 				element.setAttribute("data-sidebartype", "mini-sidebar");
	// 			} else {
	// 				element.setAttribute("data-sidebartype", settings.activeSidebarType);
	// 			}
	// 			break;

	// 		case "overlay":
	// 		case "mini-sidebar":
	// 			element.classList.toggle("full");
	// 			if (element.classList.contains("full")) {
	// 				element.setAttribute("data-sidebartype", "full");
	// 			} else {
	// 				element.setAttribute("data-sidebartype", settings.activeSidebarType);
	// 			}
	// 			break;
	// 		default:
	// 	}
	// };

	// const handleTab1 = () => {
	// 	console.log("Form closed");
	// 	getlogform();
	// };

	//const [dangerSweetAlert, setSweetAlert] = (error_modal);

	const handleSweetAlert = () => {
		console.log("Form closed");
		removeSweatAlert();
		//setSweetAlert(false);
		return <Redirect to="/client/validation-buttons" />;
		// setSweetAlert(false);
	};
	
	const toggleDropdown = () => {
	  setDropdownOpen((prevState) => !prevState);
	};
  
	const handleOptionChange = (option) => {
	  setSelectedOption(option);
	  setFinalData({ ...finalData, selectedOption: option });
	};
	const onPendingSubmit = async (e)=>{
		console.log("---------------------------------------------------------------------",finalData);
		e.preventDefault();
		//setFinalData({ ...finalData, selectedOption });
		
		// const finalDataJson = JSON.stringify(finalData);
		setFormData(finalData)
		
	}

	const onBlockedSubmit  = async (e)=>{
		console.log("---------------------------------------------------------------------",finalData);
		console.log("blocked submit func calledd",getBlockedSessions,getPendingdata)
		e.preventDefault();
		
		getBlockedSessions({limit: 0, search:autoString })

		setFormData(finalData)
		
	}

	const getPlaceholderText = (selectedOption) => {
		switch (selectedOption) {
		  case 'orgName':
			return 'Enter Org Name';
		  case 'category':
			return 'Enter Category';
		  case 'sessionId':
			return 'Enter Session ID';
		  default:
			return 'Enter Text';
		}
	  };

	return (
		<header className="topbar navbarbg" data-navbarbg={settings.activeNavbarBg}>
			{/* {error_modal ? (
				<SweetAlert info title="Something went wrong! please try after sometime" onConfirm={handleSweetAlert}>
					{errorMsg}
				</SweetAlert>
			) : (
				""
			)} */}
			{console.log("Current subheading:-"+subheading)}
			<Navbar className={"top-navbar navbar-light"} expand="md">
				<div className="navbar-header" id="logobg" data-logobg={settings.activeLogoBg}>
					{/*--------------------------------------------------------------------------------*/}
					{/* Mobile View Toggler  [visible only after 768px screen]                         */}
					{/*--------------------------------------------------------------------------------*/}
					<span className="nav-toggler d-block d-md-none" onClick={showMobilemenu.bind(null)}>
						<i className="ti-menu ti-close" />
					</span>
					{/*--------------------------------------------------------------------------------*/}
					{/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
					{/*--------------------------------------------------------------------------------*/}
					<NavbarBrand style={{ marginTop: "-58px", paddingTop: "40px", display: "flex", flexDirection: "column" }} to="/client/TableIndex">
						{/* <div>
              <img src={logodarkicon} alt="homepage"/>
            </div>
            <div style={{color:"#5dcbdd", fontSize:"18px", marginTop:"-30px"}}>
              Verification Portal
            </div> */}

						<b className="logo-icon">
							<img src={logodarkicon} className="logo-home" alt="homepage" />
							<span className="beta-login">{environment.beta}</span>
						</b>
						<span className="logo-text">
							<div style={{ color: "#5dcbdd", fontSize: "18px", marginTop: "-41px" }}>Verification Portal</div>
						</span>
					</NavbarBrand>
					
					{/*--------------------------------------------------------------------------------*/}
					{/* Mobile View Toggler  [visible only after 768px screen]                         */}
					{/*--------------------------------------------------------------------------------*/}
					<span className="topbartoggler d-block d-md-none mob-tonggle" onClick={toggle.bind(null)}>
						<i className="ti-more" />
					</span>
				</div>
				{subheading=="auto"?<div style={{width:"100%"}} className="d-flex justify-content-end">
					<Button onClick={onSubmitRefresh.bind(null)} color="warning" className="search-button search-warning reload-btnn">
									<i className="ti-reload"></i>
								</Button>
					{/* <li  style={{ width: "20vw" }}> */}
								<Form onSubmit={(e) => onSubmit(e)} >
									<InputGroup >
										<div style={{ height: "28px", width: "100%", position: "relative" }} >
											<SearchBar
												value={enteredString}
												onChange={e => onChange(e)}
												style={{ height: "28px", width: "100%", position: "relative" }}
											/>
											
										</div>
									</InputGroup>
								</Form>
							{/* </li> */}
							</div>:''}
{subheading === "pending"  || subheading ===  "blocked" ? (
  <div style={{ width: "100%" }} className="d-flex justify-content-end ">
    <Button onClick={onSubmitRefresh.bind(null)} color="warning" className="search-button search-warning reload-btnn mr-2">
      <i className="ti-reload"></i>
    </Button>
    <Form onSubmit={(e) =>{subheading === "pending" ? onPendingSubmit(e): onBlockedSubmit(e)}}>
      <InputGroup>
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle caret style={{ background: "#3498db", color: "#fff", fontSize: '3px' }}>
            {selectedOption === 'orgName' ? <i className="fa fa-building" style={{ fontSize: '18px' }}/> : selectedOption === 'category' ? <i className="fa fa-list" style={{ fontSize: '18px' }}/> : <i className="fa fa-id-card" style={{ fontSize: '18px' }}/>}
          </DropdownToggle>
		  <DropdownMenu>
            <DropdownItem onClick={() => handleOptionChange('orgName')} active={selectedOption === 'orgName'}>Organization Name</DropdownItem>
            <DropdownItem onClick={() => handleOptionChange('category')} active={selectedOption === 'category'}>Category</DropdownItem>
            <DropdownItem onClick={() => handleOptionChange('sessionId')} active={selectedOption === 'sessionId'}>Session ID</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <div style={{ height: "28px", width: "80%", position: "relative", marginLeft: "10px" }}>
          <SearchBar
            value={enteredString}
            onChange={(e) => onChange(e)}
            placeholder={getPlaceholderText(selectedOption)}
            style={{
              height: "28px",
              width: "100%",
              position: "relative",
              fontSize: '10px',
              color: '#555',
            //   backgroundColor: selectedOption ? '#eaf7ff' : 'inherit',
			  backgroundColor: '#fff'
            }}
          />
        </div>
      </InputGroup>
    </Form>
  </div>
) : null}

				<Collapse className="navbarbg" isOpen={isOpen} navbar data-navbarbg={settings.activeNavbarBg}>
					<Nav className="float-left" navbar>
				{/* <NavItem>
               <NavLink
                href="#"
                className="d-none d-md-block"
                onClick={sidebarHandler.bind(null)}
              >
                <i className="icon-arrow-left-circle"></i>
              </NavLink> 
            </NavItem> */}
						{/*--------------------------------------------------------------------------------*/}
						{/* Start Notifications Dropdown                                                   */}
						{/*--------------------------------------------------------------------------------*/}
						{/* {subheading ? subheadingdata : ""} */}
						{/* {buttonSettingbut ? <Fragment><span className="button-id-header">({ button_id}) </span> </Fragment>: ''} */}
						{/*--------------------------------------------------------------------------------*/}
						{/* End Notifications Dropdown                                                     */}
						{/*--------------------------------------------------------------------------------*/}
						{/*--------------------------------------------------------------------------------*/}
						{/* End Mega Menu Dropdown                                                         */}
						{/*--------------------------------------------------------------------------------*/}
					</Nav>

					<Nav className="ml-auto fml-auto " navbar>
						{/* {subheading === "Logs" ? (
							logTab ? (
								<UncontrolledDropdown nav inNavbar>
									<div className="settingIcon-header" onClick={handleTab1}>
										<i className="ti-settings" />
									</div>
								</UncontrolledDropdown>
							) : (
								""
							)
						) : (
							""
						)} */}
						{/* <li className="nav-item search-box d-flex align-items-center bg-transparent">
							{" "}
							<ModeTonggle />
						</li> */}
						{/* {search ? (
							<Fragment>
								<UncontrolledDropdown nav inNavbar> */}
						{/* <DropdownToggle nav caret onClick={onSubmitRefresh.bind(null)}>
                 <i className="mdi mdi-refresh"></i>
          
               </DropdownToggle> */}
						{/* </UncontrolledDropdown>

								<li className="nav-item search-box d-none d-md-block margin-left-tongle bg-transparent">
									<Form onSubmit={(e) => onSubmit(e)} className="app-search  mr-2 mb-1">
										<InputGroup className="search-form">
											<InputGroupAddon addonType="prepend">
												<Button onClick={onSubmitRefresh.bind(null)} color="warning" className="search-button search-warning">
													<i className="ti-reload"></i>
												</Button>
											</InputGroupAddon>
											<Input
												type="text"
												// className="form-control"
												placeholder="Search for..."
												name="enteredString"
												value={enteredString}
												className="search-input"
												onChange={(e) => onChange(e)}
											/>
											<InputGroupAddon addonType="append">
												<Button type="submit" className=" search-button">
													<i className="ti-search"></i>
												</Button>
											</InputGroupAddon>
										</InputGroup>
									</Form>
								</li>
							</Fragment>
						) : (
							""
						)} */}

						{/* {adduser ? (
							<li className="nav-item search-box d-none d-md-block bg-transparent">
								{" "}
								<AddUserModal />{" "}
							</li>
						) : (
							""
						)} */}
						{/* {(addbutton && roles !== "User") || (addbutton && roles !== "Account") ? (
							<li className="nav-item search-box d-flex align-items-center bg-transparent">
								{" "}
								<AddButton />{" "}
							</li>
						) : (
							""
						)} */}
						{/* {buttonSettingbut ? (
							<li className="nav-item search-box d-none d-md-block bg-transparent">
								{" "}
								<Link
									to={"/client/developers/api-reference/" + button_id}
									className="btn btn-outline-primary btn-color-diro-outline float-right mr-2 "
									style={{ marginTop: "5px", padding: "2px 5px" }}
								>
									View integration
								</Link>{" "}
							</li>
						) : (
							""
						)} */}
						{/* {buttonSettingbut ? (
							<li className="nav-item search-box d-none d-md-block bg-transparent">
								{" "}
								<Link
									to={"/client/validation-buttons/req-document/" + button_id}
									className="btn btn-outline-warning float-right  "
									style={{ marginTop: "5px", padding: "2px 5px" }}
								>
									Request doc
								</Link>{" "}
							</li>
						) : (
							""
						)} */}
						{/* {(reqdocbut && roles !== "User") || (reqdocbut && roles !== "Account") ? (
							<li className="nav-item search-box d-none d-md-block bg-transparent">
								{" "}
								<Link to={"/client/validation-buttons/button-setting/" + button_id} className=" float-right  " style={{ marginTop: "5px" }}>
									{" "}
									<i className="fas fa-cog fa-2x cog-header"></i>
								</Link>{" "}
							</li>
						) : (
							""
						)} */}

						{/*--------------------------------------------------------------------------------*/}
						{/* Start Profile Dropdown                                                         */}
						{/*--------------------------------------------------------------------------------*/}
						<UncontrolledDropdown nav inNavbar className="bg-transparent">
							<DropdownToggle nav caret className="pro-pic">
								<img
									src={user.profileimage ? user.profileimage : "https://stage.dirolabs.com/client/images/user.png"}
									alt="user"
									className="rounded sidebar "
									// width="52"
									style={{ maxHeight: "60px", maxWidth: "125px", marginTop: "-20" }}
								/>
							</DropdownToggle>
							<DropdownMenu right className="user-dd animated">
								<div className="d-flex no-block align-items-center p-3 mb-2 border-bottom">
									{/* <div className="">
                    <img
                      src={user.profileimage ? user.profileimage :("https://stage.dirolabs.com/client/images/user.png")}
                      alt="user"
                      className="rounded"
                      width="80"
                    />
                  </div> */}
									<div className="ml-2">
										<h4 className="mb-0">{login_user.name}</h4>
										<p className=" mb-0">{login_user.email}</p>
									</div>
								</div>

								{/* <Link to="/client/manage-account/organizations-details" type="button" tabIndex="0" role="menuitem" className="dropdown-item">
									<i className="ti-user mr-1 ml-1" />
									Organization
								</Link>

								<Link to="/client/manage-account/billing" type="button" tabIndex="0" role="menuitem" className="dropdown-item">
									<i className="ti-wallet mr-1 ml-1" />
									Billing
								</Link> */}

								<DropdownItem divider />
								{/* <DropdownItem>
                  <Link to="/manage-account/users"><i className="ti-settings mr-1 ml-1" />My account</Link>
                </DropdownItem> */}
								{/* <DropdownItem divider /> */}
								{/* <DropdownItem onClick={logout} href="#!">
                  <i className="fa fa-power-off mr-1 ml-1" /> Logout
                </DropdownItem> */}
								<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>

								<DropdownItem divider />
							</DropdownMenu>
						</UncontrolledDropdown>
						{/*--------------------------------------------------------------------------------*/}
						{/* End Profile Dropdown                                                           */}
						{/*--------------------------------------------------------------------------------*/}
						{/*--------------------------------------------------------------------------------*/}
						{/* Start Create New Dropdown                                                      */}
						{/*--------------------------------------------------------------------------------*/}
						{/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                <i className="flag-icon flag-icon-us"></i>
              </DropdownToggle>
              <DropdownMenu right className="animated bounceInDown">
                <DropdownItem>
                  <i className="flag-icon flag-icon-us"></i> English
                </DropdownItem>
                <DropdownItem>
                  <i className="flag-icon flag-icon-fr"></i> French
                </DropdownItem>
                <DropdownItem>
                  <i className="flag-icon flag-icon-es"></i> Spanish
                </DropdownItem>
                <DropdownItem>
                  <i className="flag-icon flag-icon-de"></i> German
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
						{/*--------------------------------------------------------------------------------*/}
						{/* End Create New Dropdown                                                        */}
						{/*--------------------------------------------------------------------------------*/}
					</Nav>
				</Collapse>
			</Navbar>
		</header>
	);
};

Header.propTypes = {
	//apiref:  PropTypes.func.isRequired,
	//getbillingOrg: PropTypes.func.isRequired,
	////changeUrltiApiIntegration: PropTypes.func.isRequired,
	//searchTable: PropTypes.func.isRequired,
	// getrequested: PropTypes.func.isRequired,
	// getReviewPending: PropTypes.func.isRequired,
	// getApproved: PropTypes.func.isRequired,
	// getRejected: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired,
	user: PropTypes.func,
	login_user: PropTypes.object,
	auth: PropTypes.object.isRequired,
	humanin:PropTypes.object.isRequired,
	// header: PropTypes.object.isRequired,
	isAuthenticated: PropTypes.bool,
	//getlogform: PropTypes.func.isRequired,
	// logTab: PropTypes.bool.isRequired,
	roles: PropTypes.string,
	error_modal: PropTypes.bool,
	errorMsg: PropTypes.object,
	removeSweatAlert: PropTypes.func.isRequired,
	viewdoc_msg: PropTypes.bool,
	 getAutodata:PropTypes.func.isRequired,
	 setSearchData:PropTypes.func.isRequired,
	 setFormData:PropTypes.func,
	 setAutoTabPaginationPageNo:PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	// header: state.header,
	humanin:state.humanin,
	isAuthenticated: state.auth.isAuthenticated,
	logform: state.logform,
	roles: state.auth.roles,
	//  autoData: state.humanin,
	//logTab: state.logform.logTab,
	errors: state.errors,
	//error_modal: state.errors.error_modal,
	viewdoc: state.viewdoc,
	//viewdoc_msg: state.viewdoc.viewdoc_msg,
	//table: state.table,
	formData : state.humanin.formData 
});

export default connect(mapStateToProps, {
	removeSweatAlert,
	//getlogform,
	logout,
 getAutodata,
 getPendingdata,
 setSearchData,
 setFormData,
 setAutoTabPaginationPageNo,
 getBlockedSessions,
	//searchTable,
	//getbillingOrg,
	//getrequested,
	//getReviewPending,
	//getApproved,
	//getRejected,
	//changeUrltiApiIntegration,
})(Header);
