import ls from "localstorage-slim";
import {
	//USER_LOADED,
	// AUTH_ERRORS,
	LOGOUT,
	ACCOUNT_DELETED,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOGIN_SUCCESS,
	LOGIN_SANDBOX,
	LOGIN_FAIL,
	FORGOT_PASSWORD,
	GET_COUNTRYS,
	UPDATE_ORG,
	ORG_ERROR,
	UPDATE_CALLBACK,
	CALLBACK_ERROR,
	START_REG_LOADER,
	START_ORG_LOADER,
	// AUTH_MODE,
	LOGIN_AUTHENITICATED,
	REFRESH_TOKEN,
} from "../actions/types";
// import { sendLogs } from "../actions/logs";

const initialState = {
	user: localStorage.getItem("alldata") ? JSON.parse(localStorage.getItem("alldata")) : "",
	login_user: localStorage.getItem("alldataa") ? JSON.parse(localStorage.getItem("alldataa")) : "",
	token: localStorage.getItem("token"),
	apikey: localStorage.getItem("apikey"),
	email: localStorage.getItem("email")?localStorage.getItem("email"):"",
	orgid: localStorage.getItem("orgid"),
	isAuthenticated: localStorage.getItem("apikey") ? true : false,
	loading: true,
	regloading: false,
	orgloader: false,
	loadingcountry: true,
	forgot: null,
	loginmsg: {},
	registersucc: {},
	registermsg: {},
	countries: [],
	orgerr: {},
	orgerrbool: false,
	orgsuccess: false,
	callsuccess: false,
	callerr: {},
	callerrbool: false,
	loginloaders: true,
	roles: localStorage.getItem("roles"),
	stripeid: localStorage.getItem("stripeid"),
	authMode: localStorage.getItem("authMode") ? JSON.parse(localStorage.getItem("authMode")) : 1,
	isTwoFactor:false,
	twoFactorId:"",
	secret:"",
	secretBase32Encoded:"",
	multiFactorEnabled:ls.get("multifactor") ? true : false,
	method:"",
	recoverCodes:[],
	loginOtp:"",
	methodId:""
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		// case AUTH_MODE:
		//   return {
		//     ...state,
		//     isAuthenticated: true,
		//     loading: false,
		//     user: localStorage.getItem('alldata') ? JSON.parse(localStorage.getItem('alldata')) : '',
		//   };
		case REFRESH_TOKEN:
			//console.log(payload.headers.authorization);
			localStorage.setItem("token", payload.headers.authorization);
			//window.location.reload();
			return {
				...state,
				...payload,
				// token:payload.headers.authorization
			};

		case GET_COUNTRYS:
			return {
				...state,
				...payload,
				countries: payload.data,
				user: localStorage.getItem("alldata") ? JSON.parse(localStorage.getItem("alldata")) : "",
				loadingcountry: false,
				// loading: false,
			};

		case LOGIN_SANDBOX:
			localStorage.setItem("authMode", 2);
			console.log("token " + payload.payload.doc.sandbox.accesstoken);
			localStorage.setItem("token", payload.payload.doc.sandbox.accesstoken);
			localStorage.setItem("isLoggedOut", "false");
			localStorage.setItem("loggedInAt", new Date());
			localStorage.setItem("apikey", payload.payload.doc.sandbox.apikey);
			localStorage.setItem("stripeid", payload.payload.doc.sandbox.stripeid);
			//localStorage.setItem('token',"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ2aXJhakBsfsdkaXJvLmlvIiwiYXBpa2V5IjoiZGRkMDdhNGQ2MmVjZGM3ZjAxMDM5MjU3NWY1MjJkOWIiLCJpYXQiOjE2NDI1MDkxODd9.TUKnb59wQZFcmdk5iLteMoM9QOCASkA0KTQn2JffTbshq6SUqy2tJwFoLLozH8RwJD_L1wvn8dvc95pmN5j-MQ");
			localStorage.setItem("alldata", JSON.stringify(payload.payload.doc));
			localStorage.setItem("alldataa", JSON.stringify(payload.payload.doc));
			localStorage.setItem("planid", payload.payload.doc.planid);
			if (payload.payload.doc.refreshToken) {
				console.log("payload.payload.doc.refreshToken " + payload.payload.doc.refreshToken);
				localStorage.setItem("refreshToken", payload.payload.doc.refreshToken);
			}
			//console.log("payload.payload.doc.country " + payload.payload.doc.country);
			if (payload.payload.doc.roles) {
				localStorage.setItem("roles", payload.payload.doc.roles[0]);
				//console.log("payload.payload.doc.roles " + payload.payload.doc.roles[0]);
			}
			if (payload.payload.doc.country) {
				localStorage.setItem("country", payload.payload.doc.country);
			} else {
				localStorage.setItem("country", "USA");
			}

			localStorage.setItem("email", payload.payload.doc.email);
			localStorage.setItem("orgid", payload.payload.doc.orgid);
			console.log("token last");
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
				apikey: payload.payload.doc.sandbox.apikey,
				email: payload.payload.doc.email,
				user: localStorage.getItem("alldata") ? JSON.parse(localStorage.getItem("alldata")) : "",
				login_user: localStorage.getItem("alldataa") ? JSON.parse(localStorage.getItem("alldataa")) : "",
				roles: payload.payload.doc.roles[0],
				stripeid: payload.payload.doc.stripeid,
				authMode: 2,
				// loginmsg : payload.payload.message,
			};

		// case LOGIN_SUCCESS:
		// 	localStorage.setItem("authMode", 1);
		// 	if (payload.headers.authorization) {
		// 		localStorage.setItem("token", payload.headers.authorization);
		// 	} else {
		// 		localStorage.setItem("token", payload.headers);
		// 	}
		// 	localStorage.setItem("isLoggedOut", "false");
		// 	localStorage.setItem("loggedInAt", new Date());
		// 	localStorage.setItem("apikey", payload.payload.doc.apikey);
		// 	//localStorage.setItem('token',"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ2aXJhakBsfsdkaXJvLmlvIiwiYXBpa2V5IjoiZGRkMDdhNGQ2MmVjZGM3ZjAxMDM5MjU3NWY1MjJkOWIiLCJpYXQiOjE2NDI1MDkxODd9.TUKnb59wQZFcmdk5iLteMoM9QOCASkA0KTQn2JffTbshq6SUqy2tJwFoLLozH8RwJD_L1wvn8dvc95pmN5j-MQ");
		// 	localStorage.setItem("alldata", JSON.stringify(payload.payload.doc));
		// 	localStorage.setItem("alldataa", JSON.stringify(payload.payload.doc));
		// 	localStorage.setItem("stripeid", payload.payload.doc.stripeid);
		// 	localStorage.setItem("planid", payload.payload.doc.planid);
		// 	if (payload.payload.doc.refreshToken) {
		// 		console.log("payload.payload.doc.refreshToken " + payload.payload.doc.refreshToken);
		// 		localStorage.setItem("refreshToken", payload.payload.doc.refreshToken);
		// 	}
		// 	//console.log("payload.payload.doc.country " + payload.payload.doc.country);
		// 	if (payload.payload.doc.roles) {
		// 		localStorage.setItem("roles", payload.payload.doc.roles[0]);
		// 		//console.log("payload.payload.doc.roles " + payload.payload.doc.roles[0]);
		// 	}
		// 	if (payload.payload.doc.country) {
		// 		localStorage.setItem("country", payload.payload.doc.country);
		// 	} else {
		// 		localStorage.setItem("country", "USA");
		// 	}

		// 	localStorage.setItem("email", payload.payload.doc.email);
		// 	localStorage.setItem("orgid", payload.payload.doc.orgid);
		// 	return {
		// 		...state,
		// 		...payload,
		// 		isAuthenticated: true,
		// 		loading: false,
		// 		apikey: payload.payload.doc.apikey,
		// 		email: payload.payload.doc.email,
		// 		user: localStorage.getItem("alldata") ? JSON.parse(localStorage.getItem("alldata")) : "",
		// 		login_user: localStorage.getItem("alldataa") ? JSON.parse(localStorage.getItem("alldataa")) : "",
		// 		roles: payload.payload.doc.roles[0],
		// 		stripeid: payload.payload.doc.stripeid,
		// 		loginmsg: "",
		// 		authMode: 1,
		// 	};
		// case LOGIN_SUCCESS:
			case LOGIN_SUCCESS:
			localStorage.setItem("authMode", 1);
			if (payload.headers.authorization) {
				localStorage.setItem("token", payload.headers.authorization);
			} else {
				localStorage.setItem("token", payload.headers);
			}
			localStorage.setItem("isLoggedOut", "false");
			localStorage.setItem("loggedInAt", new Date());
			localStorage.setItem("apikey", payload.payload.doc.apikey);
			//localStorage.setItem('token',"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ2aXJhakBsfsdkaXJvLmlvIiwiYXBpa2V5IjoiZGRkMDdhNGQ2MmVjZGM3ZjAxMDM5MjU3NWY1MjJkOWIiLCJpYXQiOjE2NDI1MDkxODd9.TUKnb59wQZFcmdk5iLteMoM9QOCASkA0KTQn2JffTbshq6SUqy2tJwFoLLozH8RwJD_L1wvn8dvc95pmN5j-MQ");
			localStorage.setItem("alldata", JSON.stringify(payload.payload.doc));
			localStorage.setItem("alldataa", JSON.stringify(payload.payload.doc));
			localStorage.setItem("stripeid", payload.payload.doc.stripeid);
			localStorage.setItem("planid", payload.payload.doc.planid);
			if (payload.payload.doc.refreshToken) {
				console.log("payload.payload.doc.refreshToken " + payload.payload.doc.refreshToken);
				localStorage.setItem("refreshToken", payload.payload.doc.refreshToken);
			}
			//console.log("payload.payload.doc.country " + payload.payload.doc.country);
			if (payload.payload.doc.roles) {
				localStorage.setItem("roles", payload.payload.doc.roles[0]);
				//console.log("payload.payload.doc.roles " + payload.payload.doc.roles[0]);
			}
			if (payload.payload.doc.country) {
				localStorage.setItem("country", payload.payload.doc.country);
			} else {
				localStorage.setItem("country", "USA");
			}

			localStorage.setItem("email", payload.payload.doc.email);
			localStorage.setItem("orgid", payload.payload.doc.orgid);
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
				apikey: payload.payload.doc.apikey,
				email: payload.payload.doc.email,
				user: localStorage.getItem("alldata") ? JSON.parse(localStorage.getItem("alldata")) : "",
				login_user: localStorage.getItem("alldataa") ? JSON.parse(localStorage.getItem("alldataa")) : "",
				roles: payload.payload.doc.roles[0],
				stripeid: payload.payload.doc.stripeid,
				loginmsg: "",
				authMode: 1,
				isTwoFactor:false
			};

		case UPDATE_ORG:
			localStorage.setItem("alldata", JSON.stringify(payload));
			// sendLogs("Org Update success", payload, "actions/auth.js");
			//console.log("payload.data " + payload)
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
				user: payload,
				orgsuccess: true,
				orgerrbool: false,
				// loginmsg : payload.payload.message,
				orgloader: false,
			};

		case ORG_ERROR:
			return {
				...state,
				...payload,
				orgerr: !payload ? null : payload.msg,
				orgerrbool: !payload ? null : payload.error,
				orgloader: false,
				orgsuccess: false,
			};

		case UPDATE_CALLBACK:
			localStorage.setItem("alldata", JSON.stringify(payload));
			//console.log("payload.data " + payload)
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
				user: payload,
				callsuccess: true,
				// loginmsg : payload.payload.message,
			};

		case CALLBACK_ERROR:
			return {
				...state,
				callerr: payload.msg,
				callerrbool: payload.error,
			};

		case FORGOT_PASSWORD:
			return {
				...state,
				forgot: payload.payload.message,
				regloading: false,
			};

		case LOGIN_FAIL:
			return {
				...state,
				loginmsg: payload.payload.message,
				//  loading: false,
			};

		case REGISTER_SUCCESS:
			return {
				...state,
				registermsg: {},
				registersucc: payload.payload.message,
				regloading: false,
			};

		case REGISTER_FAIL:
			return {
				...state,
				registermsg: payload.payload?.message ? payload.payload?.message : payload.fieldErrors,
				regloading: false,
			};

		case START_REG_LOADER:
			return {
				...state,
				regloading: true,
			};

		case START_ORG_LOADER:
			return {
				...state,
				orgloader: true,
			};

		//case REGISTER_FAIL:
		// case AUTH_ERRORS:
		case LOGIN_AUTHENITICATED: 
		console.log('user authenticated successfully 2 fa enabled')
				console.log(JSON.stringify(payload))
				console.log(JSON.stringify(payload.payload))
				ls.set('isTwoFactor',true)
				return {
					...state,
					...payload,
					// loading: false,
					email: payload.email,
					isTwoFactor:true,
					isAuthenticated:false,
					loading:true,
					twoFactorId:payload.payload.twoFactorId,
					multiFactorEnabled:payload.payload.multiFactorEnabled,
					method:payload.payload.method,
					methodId:payload.payload.methodId
				}

		case LOGOUT:
		case ACCOUNT_DELETED:
			localStorage.removeItem("token");
			localStorage.removeItem("alldata");
			localStorage.removeItem("apikey");
			localStorage.removeItem("email");
			localStorage.removeItem("orgid");
			localStorage.removeItem("roles");
			localStorage.removeItem("stripeid");
			localStorage.removeItem("planid");
			localStorage.removeItem("nickname");
			localStorage.removeItem("flat_amount");
			localStorage.removeItem("refreshToken");
			localStorage.removeItem("firstbtnid");
			// window.location.reload();
			return {
				...state,
				token: null,
				alldata: null,
				apikey: null,
				email: null,
				isAuthenticated: false,
				loading: true,
				user: null,
				orgid: null,
				roles: null,
				stripeid: null,
			};
		default:
			return state;
	}
}
