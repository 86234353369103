// LAYOUT
export const LOGO_BG = "LOGO_BG";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const THEME = "THEME";
export const DIRECTION = "DIRECTION";
export const SIDEBAR_POSITION = "SIDEBAR_POSITION";
export const HEADER_POSITION = "HEADER_POSITION";
export const LAYOUT = "LAYOUT";
export const SIDEBAR_TYPE = "SIDEBAR_TYPE";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SANDBOX = "LOGIN_SANDBOX";
export const DOWNLOAD_PRIVACY="DOWNLOAD_PRIVACY";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const GET_PDF = "GET_PDF";
export const GET_JSON = "GET_JSON";
export const HI_ERROR = "HI_ERROR";
export const CHATGPT_RESPONSE_ERROR="CHATGPT_RESPONSE_ERROR";
export const  LOADING_ANSWER_ERROR=" LOADING_ANSWER_ERROR";

export const GET_PENDING_DATA = "GET_PENDING_DATA";
export const GET_QA_DATA = "GET_QA_DATA";
export const GET_APPROVED_DATA = "GET_APPROVED_DATA";
export const GET_PENDIND_LINK_DATA="GET_PENDIND_LINK_DATA"
export const GET_AUTO_DATA = "GET_AUTO_DATA";

export const GET_HI_DATA = "GET_HI_DATA";
export const UPDATE_JSON = "UPDATE_JSON";
export const GET_QUESTION_ANSWER="GET_QUESTION_ANSWER";
export const SEND_TO_QA="SEND_TO_QA";
export const CLEAR_TABLE="CLEAR_TABLE";
export const CLEAR_PENDING_TABLE="CLEAR_PENDING_TABLE";
export const CLEAR_ALERT_TABLE="CLEAR_ALERT_TABLE";
export const SEND_TO_BAD="SEND_TO_BAD";
export const DELETE_TEMPLATE="DELETE_TEMPLATE"
export const GET_COUNTRY_DATA="GET_COUNTRY_DATA"
export const UPDATE_LINK="UPDATE_LINK"
export const RESET_UPDATELINKLOADINGSTATUS="RESET_UPDATELINKLOADINGSTATUS"
export const GET_CHATGPT_QUESTION_ANSWER="GET_CHATGPT_QUESTION_ANSWER"
export const SET_SUBHEADING="SET_SUBHEADING"
// USER
export const USER_LOADED = 'USER_LOADED';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const GET_COUNTRYS = 'GET_COUNTRYS';
// export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
// export const LOGIN_FAIL = 'LOGIN_FAIL';
// export const SET_ALERT = 'SET_ALERT';
// export const REMOVE_ALERT = 'REMOVE_ALERT';


//TABLE
export const GET_REQUESTS = "GET_REQUESTS";
export const GET_PENDINGS = "GET_PENDINGS";
export const GET_COMPLETED = "GET_COMPLETED";
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_APPROVES = "GET_APPROVES";
export const GET_REJECTS = "GET_REJECTS";
export const TABLE_ERROR = "TABLE_ERROR";
export const TABLE_LOADER = "TABLE_LOADER";
export const SET_AUTOTAB_PAGINATION="SET_AUTOTAB_PAGINATION";
export const SET_ALERT_TAB_PAGINATION = "SET_ALERT_TAB_PAGINATION";

//BUTTON 
export const GET_BUTTON  = "GET_BUTTON";
export const GET_BUTTONS  = "GET_BUTTONS";
export const POST_BUTTON  = "POST_BUTTON";
export const CLEAR_BUTTON  = "CLEAR_BUTTON";
export const ADD_BUTTON = "ADD_BUTTON";
export const DELETE_BUTTON = "DELETE_BUTTON";
export const REMOVE_BUTTON_ALERT = "REMOVE_BUTTON_ALERT";
export const UPDATE_BUTTON = "UPDATE_BUTTON";
export const GET_MASTERFIELDDATA="GET_MASTERFIELDDATA" 
export const IS_REMOVING_REDUNTANT_TEMPLATES="IS_REMOVING_REDUNTANT_TEMPLATES"

// LOGS
export const GET_LOGS = "GET_LOGS";
export const GET_LOG = "GET_LOG";
export const LOGS_ERROR = "LOGS_ERROR";
export const CLEAR_LOG = "CLEAR_LOG";
export const GET_LOGS_DATA = "GET_LOGS_DATA";
export const GET_LOGS_FORM = "GET_LOGS_FORM";

// Organization Details
export const CLEAR_ORG = "CLEAR_ORG";
export const GET_ORG = "GET_ORG";
export const ORG_ERROR = "ORG_ERROR";
export const UPDATE_ORG = "UPDATE_ORG";
export const GET_ORG_ITEM = "GET_ORG_ITEM";

// BILLING
export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const TRANSACTION_ERROR = "TRANSACTION_ERROR";
export const GET_BILLING = "GET_BILLING";
export const BILLING_ERROR = "BILLING_ERROR";


//Header subheading
export const GET_REQUESTS_HEADER = "GET_REQUESTS_HEADER";
export const GET_PENDINGS_HEADER = "GET_PENDINGS_HEADER";
export const GET_COMPLETED_HEADER = "GET_COMPLETED_HEADER";
export const GET_REVIEWS_HEADER = "GET_REVIEWS_HEADER";
export const GET_APPROVES_HEADER = "GET_APPROVES_HEADER";
export const GET_REJECTS_HEADER = "GET_REJECTS_HEADER";
export const GET_VALIDATION_HEADER = "GET_VALIDATION_HEADER";
export const GET_COVERAGE_HEADER = "GET_COVERAGE_HEADER";
export const GET_LOGS_HEADER = "GET_LOGS_HEADER";
export const GET_REFERENCE_HEADER = "GET_REFERENCE_HEADER";
export const GET_USER_HEADER = "GET_USER_HEADER";
export const GET_BILLING_HEADER = "GET_BILLING_HEADER";
export const GET_ORG_HEADER = "GET_ORG_HEADER";
export const GET_BUTTON_SETTING_HEADER = "GET_BUTTON_SETTING_HEADER";
export const GET_VIEW_DOC_HEADER = "GET_VIEW_DOC_HEADER";
export const GET_API_BUTTON = "GET_API_BUTTON";
export const SET_SUBHEADING_HEADER="SET_SUBHEADING_HEADER"
export const SET_SEARCH_DATA="SET_SEARCH_DATA"

//Alert
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_ERRORS = 'AUTH_ERRORS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const AUTH_MODE = 'AUTH_MODE';

//USERS
export const GET_USER = 'GET_USER';
export const DELETE_USERS = 'DELETE_USERS';
export const EDIT_USERS = 'EDIT_USERS';
export const ADD_USER = 'ADD_USER';
export const GET_USERS = 'GET_USERS';
export const CLEAR_USER = 'CLEAR_USER';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const ADD_USER_ERROR = "ADD_USER_ERROR";
export const EDIT_USER = "EDIT_USER";

export const GET_INTEGRATION_HEADER = "GET_INTEGRATION_HEADER";

//Callback
export const UPDATE_CALLBACK = "UPDATE_CALLBACK";
export const CALLBACK_ERROR = "CALLBACK_ERROR";

//Loader
export const START_REG_LOADER = "START_REG_LOADER";
export const START_LOGIN_LOADER = "START_LOGIN_LOADER";
export const START_ORG_LOADER = "START_ORG_LOADER";

//User alert
export const STOP_USER_ALERT = "STOP_USER_ALERT";

//Request document
export const GET_TEST_PREVIEW = "GET_TEST_PREVIEW";
export const GET_SEND_REQUEST = "GET_SEND_REQUEST";
export const GET_INVITE_LINK = "GET_INVITE_LINK";
export const REQ_ERROR = "REQ_ERROR";
export const REQ_LOADER = "REQ_LOADER";
export const EMPTY_BLANK_URL = "EMPTY_BLANK_URL";

export const LOAD_BUTTON = "LOAD_BUTTON";

export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const REMOVE_AUTH_ERROR = "REMOVE_AUTH_ERROR";

export const POST_FEEDBACK_FORM = "POST_FEEDBACK_FORM";
export const REMOVE_FEEDBACK_ALERT = "REMOVE_FEEDBACK_ALERT";

export const GET_PDF_DATA = "GET_PDF_DATA";
export const GET_DOCUMENT_DATA = "GET_DOCUMENT_DATA";
export const VIEW_DOC_LOADER = "VIEW_DOC_LOADER";

export const GET_APPROVE_DATA = "GET_APPROVE_DATA";
export const GET_REJECT_DATA = "GET_REJECT_DATA";
export const GET_VIEW_DOC_MESSAGE = "GET_VIEW_DOC_MESSAGE";
export const REMOVE_VIEW_DOC_MESSAGE = "REMOVE_VIEW_DOC_MESSAGE";

export const PDF_LOADER = "PDF_LOADER";
export const PDF_TO_JSON_DATA = "PDF_TO_JSON_DATA";
export const PDF_TO_JSON_ERROR = "PDF_TO_JSON_ERROR";
export const GET_HI_QUESTION_DATA="GET_HI_QUESTION_DATA";
export const GET_HI_QUESTION_CHATGPT_DATA="GET_HI_QUESTION_CHATGPT_DATA";
export const GET_TRANSLATEPDF_DATA="GET_TRANSLATEPDF_DATA";
export const GET_QUESTIONMODAL_ANSWER="GET_QUESTIONMODAL_ANSWER";
export const GET_QUESTIONMODAL_ANSWER_FROM_CHATGPT="GET_QUESTIONMODAL_ANSWER_FROM_CHATGPT";
export const REMOVE_QUESTIONMODAL_ANSWER="REMOVE_QUESTIONMODAL_ANSWER";
export const REMOVE_QUESTIONMODAL_CHATGPT_ANSWER="REMOVE_QUESTIONMODAL_CHATGPT_ANSWER";
export const REMOVE_HI_QUESTION_DATA="REMOVE_HI_QUESTION_DATA";
export const REMOVE_HI_QUESTION_CHATGPT_DATA="REMOVE_HI_QUESTION_CHATGPT_DATA";
export const GET_BOUNDINGBOX_DATA="GET_BOUNDINGBOX_DATA";
export const GET_BOUNDINGBOX_DATA_ANCHORTEXT="GET_BOUNDINGBOX_DATA_ANCHORTEXT";
export const GET_SESSION_REPORT="GET_SESSION_REPORT";
export const GET_PROCESSED_DATA_FROM_CHATGPT="GET_PROCESSED_DATA_FROM_CHATGPT";
export const LOADING_CHATGPT_QUESTION_ANSWER="LOADING_CHATGPT_QUESTION_ANSWER";
export const LOADING_QUESTION_ANSWER="LOADING_QUESTION_ANSWER";
export const LOADING_TRANSLATE_PDF_ERROR="LOADING_TRANSLATE_PDF_ERROR";
export const LOGIN_AUTHENITICATED="LOGIN_AUTHENITICATED"

export const API_EXECUTION_COMPLETED="API_EXECUTION_COMPLETED";

//Form data for Pending Tab
export const SET_FORM_DATA = "SET_FORM_DATA";

// Alert Tab Data
export const GET_ALERT = "GET_ALERT";

// Transactions Tab Data
export const CLEAR_PENDING_TRANSACTIONS = "CLEAR_PENDING_TRANSACTIONS";
export const PENDING_TRANSACTIONS = "PENDING_TRANSACTIONS";
export const GET_LIVE_EXTRACTION = "GET_LIVE_EXTRACTION";
export const CLEAR_APPROVED_TRANSACTIONS = "CLEAR_PENDING_TRANSACTIONS";
export const GET_APPROVED_TRANSACTIONS = "GET_APPROVED_TRANSACTIONS";
export const GET_TRANSACTION_EXTRACTION = "GET_TRANSACTION_EXTRACTION";
export const APPROVE_TRANSACTION = "APPROVE_TRANSACTION";
export const TRANSACTION_SEND_TO_BAD = "TRANSACTION_SEND_TO_BAD";
export const GET_COMPLETED_TRANSACTIONS = "GET_COMPLETED_TRANSACTIONS";
export const GET_QA_TRANSACTIONS = "GET_QA_TRANSACTIONS";
export const REDO_TRANSACTION = "REDO_TRANSACTION";
export const GET_ERROR_DATA_BY_SESSION_ID="GET_ERROR_DATA_BY_SESSION_ID";
export const GET_BLOCKED_SESSIONS="GET_BLOCKED_SESSIONS";