import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import Spinner from "./views/spinner/Spinner";
import "./assets/scss/style.scss";
import App from './app'
import { Provider } from 'react-redux';
import { configureStore } from "./Store";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from '@sentry/integrations';
import { env as environment } from "./actions/environment";

if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
}
// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en.json'
// import ru from 'javascript-time-ago/locale/ru.json'
// TimeAgo.addDefaultLocale(en)
// TimeAgo.addLocale(ru)

Sentry.init({
	dsn: environment.sentryDSN,
	integrations: [
		new Sentry.BrowserTracing(),
		new CaptureConsole({
			levels: ['error']
		})
	],

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
});

ReactDOM.render(
 
    <Provider store={configureStore()}>
      <Suspense fallback={<Spinner />}>
        <App />
      </Suspense>,
    </Provider>,
  document.getElementById("root")
);
